@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700');
@import './global-defs';

html, body {
  font-size: 1.2rem;
}

body {
  font-family: $font-family !important;
  -webkit-app-region: drag;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
