@import '../../global-defs';

.keyboard-grid {
  .cellphone-input {
    background-color: white;
    text-align: center;
  }

  .cellphone-input-valid {
    color: $onyo-green;
  }

  .cellphone-input-invalid {
    color: $onyo-red;
  }

  .backspace-button {
    @include button-states;
    background-color: rgba($color: $onyo-orange, $alpha: 0);
    border-color: $text-color;
  }
}
