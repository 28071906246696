// Color scheme:
$onyo-orange: #f65a02;
$onyo-light-orange: #fd7e35;
$onyo-dark-orange: #d01e1e;
$onyo-red: #d01e1e;
$onyo-brown: #7e2f02;
$onyo-green: #20c872;
$onyo-purple: #520075;
$text-color: #fff;

@mixin button-states {
  -webkit-app-region: no-drag; // Make sure the buttons are clickable

  &:active {
    background-color: $onyo-dark-orange !important;
    border-color: $text-color !important;
  }

  &:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, .5) !important;
  }

  &:focus {
    box-shadow: none;
  }
}

// Fonts
$font-family: 'Quicksand';
