@import '../../global-defs';

.keyboard-key {

  .key-button {
    @include button-states;

    color: $text-color;
    font-weight: bold;
    border: solid 2px;
    border-color: $text-color;
    background-color: rgba($color: $onyo-orange, $alpha: 0);
    width: 4rem;
  }

  .key-icon {
    color: $text-color;
  }

}
