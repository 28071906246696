@import url(https://fonts.googleapis.com/css?family=Quicksand:400,700);
html, body {
  font-size: 1.2rem; }

body {
  font-family: "Quicksand" !important;
  -webkit-app-region: drag;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.app {
  width: 100vw;
  height: 100vh;
  background: #f65a02; }
  .app .onyo-button {
    -webkit-app-region: no-drag;
    background: #f65a02;
    border-color: #fff;
    font-weight: bold; }
    .app .onyo-button:active {
      background-color: #d01e1e !important;
      border-color: #fff !important; }
    .app .onyo-button:active:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5) !important; }
    .app .onyo-button:focus {
      box-shadow: none; }
  .app .onyo-logo {
    height: 3rem; }

.keyboard-grid .cellphone-input {
  background-color: white;
  text-align: center; }

.keyboard-grid .cellphone-input-valid {
  color: #20c872; }

.keyboard-grid .cellphone-input-invalid {
  color: #d01e1e; }

.keyboard-grid .backspace-button {
  -webkit-app-region: no-drag;
  background-color: rgba(246, 90, 2, 0);
  border-color: #fff; }
  .keyboard-grid .backspace-button:active {
    background-color: #d01e1e !important;
    border-color: #fff !important; }
  .keyboard-grid .backspace-button:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5) !important; }
  .keyboard-grid .backspace-button:focus {
    box-shadow: none; }

.keyboard-key .key-button {
  -webkit-app-region: no-drag;
  color: #fff;
  font-weight: bold;
  border: solid 2px;
  border-color: #fff;
  background-color: rgba(246, 90, 2, 0);
  width: 4rem; }
  .keyboard-key .key-button:active {
    background-color: #d01e1e !important;
    border-color: #fff !important; }
  .keyboard-key .key-button:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5) !important; }
  .keyboard-key .key-button:focus {
    box-shadow: none; }

.keyboard-key .key-icon {
  color: #fff; }

