@import '../../global-defs';

.app {
  width: 100vw;
  height: 100vh;
  background: $onyo-orange;

  .onyo-button {
    @include button-states;
    background: $onyo-orange;
    border-color: $text-color;
    font-weight: bold;
  }

  .onyo-logo {
    height: 3rem;
  }
}
